@import '../node_modules/prismjs/themes/prism-tomorrow';
@import './assets/scss/app.scss';

body {
  .react-select__menu-portal {
    z-index: 1033 !important;

    @media screen and (max-width: 768px) {
      z-index: 1051 !important;
      
    }
  }
}
