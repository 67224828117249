.apexchart-custom__tooltip {
  padding: 5px 10px;
}

.custom-switch {
  z-index: 0 !important;
}

.text-gray {
  color: #a8a8a8;
}

.custom-switch-sm {
  .custom-control-label {
    transform: scale(0.75);
  }
}

.input-has-append-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-has-append-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
